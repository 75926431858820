import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';

export const Api = axios.create({
  baseURL: import.meta.env['VITE_API_URL'],
});

export const patch = async <T>(
  url: string,
  payload?: object,
  headers?: object,
): Promise<T> => {
  const response = await Api.patch(url, payload, { headers });
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.data);
  }
};

export const del = async <T>(
  url: string,
  payload?: object,
  headers?: object,
): Promise<T> => {
  const response = await Api.delete(url, { data: payload, headers });
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.data);
  }
};

export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await Api.get<T>(url, config);
  return response.data;
};

export const post = async <T>(
  url: string,
  payload?: object,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await Api.post(url, payload, config);
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(response.data);
  }
};

export const put = async <T>(
  url: string,
  payload?: object,
  config?: AxiosRequestConfig,
): Promise<T> => {
  const response = await Api.put(url, payload, config);
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.data);
  }
};

export const postFormData = async (
  url: string,
  payload?: FormData,
): Promise<AxiosResponse> => {
  const response = await Api.post(url, payload);
  if (response.status === 201) {
    return response.data;
  } else {
    throw new Error(response.data);
  }
};

export const patchMiniGames = async <T>(
  url: string,
  data: object,
  headers?: object,
): Promise<T> => {
  const response = await Api.patch(url, data, { headers });
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(response.data);
  }
};
