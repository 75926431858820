<script lang="ts">
  import { onMount } from 'svelte';

  import type { ISessionSchedule } from '../../interfaces/ISessionSchedule';
  import Input from '../../lib/components/input/Input.svelte';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import ButtonUi from '../../lib/components/paginator/ButtonUI.svelte';
  import { ToastNotification } from '../../lib/services/toast';
  import {
    createSessionSchedule,
    getSessionSchedule,
    updateSessionSchedule,
  } from '../../lib/services/together/together.api';

  export let englishExperienceOptions: { value: string; text: string }[] = [];
  export let spanishExperienceOptions: { value: string; text: string }[] = [];

  let scheduleSessions: Record<string, ISessionSchedule | null> = {};
  let selectedEnglishExperienceIds: Record<string, string> = {};
  let selectedSpanishExperienceIds: Record<string, string> = {};
  let suggestedDates: Record<string, string | undefined> = {};
  let isLoading = true;

  const events: { eventName: string; dateField: boolean }[] = [
    { eventName: 'Free Trial', dateField: false },
  ];

  const handleSubmit = async (eventName: string) => {
    const payload = {
      eventName,
      englishExperienceId: selectedEnglishExperienceIds[eventName]
        ? +selectedEnglishExperienceIds[eventName]
        : undefined,
      spanishExperienceId: selectedSpanishExperienceIds[eventName]
        ? +selectedSpanishExperienceIds[eventName]
        : undefined,
      suggestedDate: suggestedDates[eventName]
        ? new Date(suggestedDates[eventName])
        : undefined,
    };

    try {
      if (!scheduleSessions[eventName]) {
        scheduleSessions[eventName] = await createSessionSchedule(payload);
        ToastNotification.success(`Session schedule created successfully`);
      } else {
        await updateSessionSchedule(scheduleSessions[eventName]!.id, payload);
        ToastNotification.success(`Session schedule updated successfully`);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || 'An error occurred';
      ToastNotification.error(errorMessage);
    }
  };

  onMount(async () => {
    const promises = events.map(async (event) => {
      try {
        const result = await getSessionSchedule(event.eventName);
        scheduleSessions[event.eventName] = result;

        selectedEnglishExperienceIds[event.eventName] = String(
          result.englishExperience?.id || '',
        );
        selectedSpanishExperienceIds[event.eventName] = String(
          result.spanishExperience?.id || '',
        );
        suggestedDates[event.eventName] = result.suggestedDate
          ? new Date(result.suggestedDate).toISOString().split('T')[0]
          : undefined;
      } catch (error) {
        scheduleSessions[event.eventName] = null;
        selectedEnglishExperienceIds[event.eventName] = '';
        selectedSpanishExperienceIds[event.eventName] = '';
        suggestedDates[event.eventName] = undefined;
      }
    });

    await Promise.allSettled(promises);
    isLoading = false;
  });
</script>

{#if isLoading}
  <Loading loadingSize="h-20 w-20" />
{:else}
  <table>
    <thead>
      <tr class="text-left">
        <th class="p-2">Event</th>
        <th class="p-2">English experience</th>
        <th class="p-2">Spanish experience</th>
        <th class="p-2">Suggested date</th>
      </tr>
    </thead>
    <tbody>
      {#each events as event}
        <tr>
          <td>{event.eventName}</td>
          <td class="p-2">
            <Input
              testId={`english-experience-${event.eventName}`}
              type="select"
              bind:value={selectedEnglishExperienceIds[event.eventName]}
              options={englishExperienceOptions}
            />
          </td>
          <td class="p-2">
            <Input
              testId={`spanish-experience-${event.eventName}`}
              type="select"
              bind:value={selectedSpanishExperienceIds[event.eventName]}
              options={spanishExperienceOptions}
            />
          </td>
          <td class="p-2">
            {#if event.dateField}
              <Input
                testId={`suggested-date-${event.eventName}`}
                type="date"
                bind:value={suggestedDates[event.eventName]}
              />
            {:else}
              <span class="text-gray-500">N/A</span>
            {/if}
          </td>
          <td>
            <ButtonUi
              testId={`save-${event.eventName}`}
              on:click={() => handleSubmit(event.eventName)}
            >
              Save
            </ButtonUi>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}
