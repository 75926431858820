<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { link } from 'svelte-navigator';

  import type { IGameConfig } from '../../..//routes/games/IGamesResponse';

  export let games: IGameConfig[];

  const dispatch = createEventDispatcher();
</script>

<table class="w-full text-sm text-left text-gray-500">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th scope="col" class="px-6 py-3">Id</th>
      <th scope="col" class="px-6 py-3">Game name</th>
      <th scope="col" class="px-6 py-3">Title</th>
      <th scope="col" class="px-6 py-3">Action</th>
    </tr>
  </thead>
  <tbody>
    {#each games as game}
      <tr class="bg-white border-b">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900"
          >{game.id}</th
        >
        <td class="px-5 py-3">{game.gameName}</td>
        <td class="px-5 py-3 max-w-[200px] truncate">{game.title}</td>
        <td class="px-5 py-3">
          <a
            href={`/admin/game/${game.id}`}
            use:link
            class="font-medium text-primary hover:underline">View</a
          >
          <a
            href={`/admin/edit/game/${game.id}`}
            use:link
            class="font-medium text-primary hover:underline">Edit</a
          >
          <button
            on:click={() =>
              dispatch('deleteGame', {
                gameId: game.id,
                gameTitle: game.gameName,
              })}
            class="font-medium text-primary hover:underline">Delete</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>
