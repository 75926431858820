<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import {
    type ISubmission,
    SubmissionStatusEnum,
  } from '../../../routes/submissions/ISubmissionsResponse';

  export let submissions: ISubmission[];

  const dispatch = createEventDispatcher();
</script>

<table class="w-full text-sm text-left text-gray-500">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th scope="col" class="px-6 py-3">Id</th>
      <th scope="col" class="px-6 py-3">Organization</th>
      <th scope="col" class="px-6 py-3">Customer name</th>
      <th scope="col" class="px-6 py-3">Customer email</th>
      <th scope="col" class="px-6 py-3">Trial Session Date</th>
      <th scope="col" class="px-6 py-3">Status</th>
      <th scope="col" class="px-6 py-3">Action</th>
    </tr>
  </thead>
  <tbody>
    {#each submissions as submission}
      <tr class="bg-white border-b">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900"
          >{submission.id}</th
        >
        <td class="px-5 py-3">{submission.orgName}</td>
        <td class="px-5 py-3 max-w-[200px] truncate"
          >{`${submission.name} ${submission.lastName}`}</td
        >
        <td class="px-5 py-3 max-w-[200px] truncate">{submission.email}</td>
        <td class="px-5 py-3"
          >{submission?.trialSessionDate
            ? new Date(submission.trialSessionDate).toLocaleDateString()
            : '-'}</td
        >
        <td
          class={`px-5 py-3 capitalize 
          ${
            submission.status === SubmissionStatusEnum.APPROVED
              ? 'text-green-500'
              : submission.status === SubmissionStatusEnum.PENDING
              ? 'text-blue-500'
              : 'text-red-500'
          }`}>{submission.status}</td
        >
        <td class="px-5 py-3">
          <button
            disabled={submission.status !== SubmissionStatusEnum.PENDING}
            on:click={() =>
              dispatch('approveSubmission', {
                submissionId: submission.id,
              })}
            class="disabled:opacity-50 disabled:no-underline font-medium text-primary hover:underline px-2"
            >Approve</button
          >
          <button
            disabled={submission.status !== SubmissionStatusEnum.PENDING}
            on:click={() =>
              dispatch('rejectSubmission', {
                submissionId: submission.id,
              })}
            class="disabled:opacity-50 disabled:no-underline font-medium text-primary hover:underline px-2"
            >Reject</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>
