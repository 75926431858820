<script lang="ts">
  import StarIcon from '../../../assets/svg/StarIcon.svelte';
  import Input from '../../../lib/components/input/Input.svelte';

  export let ratingFilter: number;
  export let fromDate: string | undefined;
  export let toDate: string | undefined;
  export let cardsPerPage: number | undefined;
  const ratingOptions: number[] = [1, 2, 3, 4, 5];
</script>

<div class="flex flex-col gap-2">
  <div class="flex flex-row gap-2 justify-between">
    <div class="flex flex-row items-center gap-2">
      <span class="font-semibold">From:</span>
      <Input
        testId="feedback-from-date"
        type="date"
        bind:value={fromDate}
        max={new Date().toISOString().split('T')[0]}
      />
    </div>
    <div class="flex flex-row items-center gap-2">
      <span class="font-semibold">To:</span>
      <Input
        testId="feedback-to-date"
        type="date"
        bind:value={toDate}
        max={new Date().toISOString().split('T')[0]}
      />
    </div>
  </div>
  <div class="w-full flex flex-row gap-4">
    <div class=" w-full flex flex-row items-center gap-2">
      <span class="font-semibold">Rating</span>
      {#each ratingOptions as option}
        <StarIcon
          testId={`feedback-star-${option}`}
          style={`hover:cursor-pointer hover:fill-blue-500 ${
            option <= ratingFilter ? 'fill-primary' : 'fill-grey'
          } w-6 h-6`}
          on:click={() =>
            ratingFilter === option
              ? (ratingFilter = 0)
              : (ratingFilter = option)}
        />
      {/each}
    </div>
    <div class="flex flex-row items-center justify-between gap-2">
      <span class="text-sm font-semibold">Cards</span>
      <Input
        testId="feedback-cards-per-page"
        type="number"
        bind:value={cardsPerPage}
        min={2}
        styleClass="w-16"
      />
    </div>
  </div>
</div>
