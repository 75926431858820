<script lang="ts">
  import moment from 'moment';

  import StarIcon from '../../../assets/svg/StarIcon.svelte';
  import UserIcon from '../../../assets/svg/UserIcon.svelte';
  import type { ISessionUser } from '../../../interfaces/ISessionUser';
  import { ToastNotification } from '../../../lib/services/toast';
  import { changeFeedbackVisibility } from '../../../lib/services/together/together.api';

  export let sessionUser: ISessionUser;

  const handleChangeVisibility = async () => {
    try {
      await changeFeedbackVisibility(sessionUser.id);
      sessionUser.isShown = !sessionUser.isShown;
      ToastNotification.success('Feedback visibility changed');
    } catch (error) {
      ToastNotification.error('Failed to change feedback visibility');
    }
  };
</script>

<div
  data-cy="feedback-card-{sessionUser.id}"
  class="grid h-fit w-72 shrink-0 grid-cols-5 overflow-hidden rounded-lg border border-light-grey p-4"
>
  <div class="h-full w-fit grid-cols-1 items-center">
    <UserIcon style="fill-grey w-9 h-9" />
  </div>
  <div class="col-span-4 flex w-full flex-col">
    <div class="flex flex-row justify-between">
      <span class="text-sm font-bold text-dark-grey"
        >{sessionUser.user.name} {sessionUser.user.lastName}</span
      >
      <div class="flex flex-col items-end">
        <span class="text-xs text-grey"
          >{moment(sessionUser.updatedAt).fromNow()}</span
        >
        <div class="flex flex-row items-center gap-1">
          <span class="text-xs text-grey">{sessionUser.feedbackRating}</span
          ><StarIcon style="fill-primary w-2.5 h-2.5" />
        </div>
      </div>
    </div>
    <div class="w-full h-20 overflow-auto">
      <p class="text-xs text-grey">
        {sessionUser.feedbackComment}
      </p>
    </div>
    <div class="mt-2">
      <input
        type="checkbox"
        id={'feedback-checkbox-' + sessionUser.id}
        class="hidden"
        checked={sessionUser.isShown}
        on:change={handleChangeVisibility}
        data-cy={'feedback-visibility-checkbox-' + sessionUser.id}
      />
      <label
        for={'feedback-checkbox-' + sessionUser.id}
        class="flex flex-row items-center justify-end gap-2"
        data-cy={'feedback-visibility-label-' + sessionUser.id}
      >
        <span class="text-xs text-grey">Is Shown?</span>
        <div class="relative h-5 w-9 cursor-pointer rounded-full bg-grey">
          <div
            class={`absolute left-0 top-0 h-5 w-5 rounded-full transition ${
              sessionUser.isShown
                ? 'bg-primary transform translate-x-4'
                : 'bg-white transform translate-x-0'
            }`}
          />
        </div>
      </label>
    </div>
  </div>
</div>
