<script lang="ts">
  import type { IMiniGameConfig } from '../../../routes/mini-games/IMiniGamesResponse';
  import type { IContent } from '../mini-games-forms/casino-roulette/ICasinoRouletteGameConfig';
  import { MiniGameNamesEnum } from '../mini-games-forms/casino-roulette/ICasinoRouletteGameConfig';

  export let gameConfig: IMiniGameConfig;
  export let gameName: string;

  let gameConfigContent: IContent = JSON.parse(gameConfig.content);
</script>

{#if gameName === MiniGameNamesEnum.Roulette}
  <div class="flex flex-col pt-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Amount of Items</dt>
    <dd class="text-lg font-semibold">
      {gameConfigContent.items.length}
    </dd>
  </div>
  <div class="flex flex-col py-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Items</dt>

    <dd class="text-lg font-semibold">
      <ul class="list-decimal p-2">
        {#each gameConfigContent.items as item, i}
          <li class="mx-4">{i}.{item.item} {item.description}</li>
        {/each}
      </ul>
    </dd>
  </div>
{/if}
