<script lang="ts">
  import { toast } from '@zerodevx/svelte-toast';
  import { link } from 'svelte-navigator';

  import Gamepad from '../../assets/svg/Gamepad.svelte';
  import DangerAlert from '../../lib/components/alerts/DangerAlert.svelte';
  import DeleteResource from '../../lib/components/delete-resource/DeleteResourceToast.svelte';
  import GamesTables from '../../lib/components/tables/GamesTables.svelte';
  import { get } from '../../lib/services/api-axios';
  import { ToastNotification } from '../../lib/services/toast';
  import type { IGamesResponse } from './IGamesResponse';

  let canBeDeleted = true;

  const getGames = async () => {
    return await get<IGamesResponse>('/game-config');
  };

  let gamesPromise: Promise<IGamesResponse> = getGames();

  const handleDeleteGame = async (event: CustomEvent<any>) => {
    if (canBeDeleted) {
      canBeDeleted = false;
      ToastNotification.removeToasts();

      ToastNotification.customizable({
        component: {
          src: DeleteResource,
          props: {
            title: 'Delete a game',
            resourceTitle: `${event.detail.gameTitle}`,
            resourceId: `${event.detail.gameId}`,
            resourceName: 'game',
            resourceUrlPath: 'game-config',
          },
          sendIdTo: 'toastId',
        },
        target: 'new-container',
        reversed: true,
        dismissable: false,
        initial: 0,
        theme: {
          '--toastPadding': '0',
          '--toastMsgPadding': '0',
          '--toastBackground': 'transparent',
          '--toastBorderRadius': '1rem',
        },
        intro: { y: -192 },
        onpop: () => {
          const hasBeenDeleted = $toast.some(
            (item) => item.msg === 'The game has been deleted successfully!',
          );

          if (hasBeenDeleted) {
            gamesPromise = getGames();
          }
          canBeDeleted = true;
        },
      });
    }
  };
</script>

<div class="flex flex-col gap-4 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-3xl p-2 font-bold">List of games</h3>
  </div>

  {#await gamesPromise}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full animate-pulse"
    >
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-100 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex items-center" />
          <div class="flex flex-col" />
        </div>
      </div>
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-100 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row justify-center items-center p-2" />
      </div>
    </div>
    <div
      class="relative overflow-x-auto h-48 bg-white border border-gray-100 shadow-sm sm:rounded-lg animate-pulse"
    />
  {:then response}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full"
    >
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex items-center">
            <div class="bg-gray-200 rounded-full p-3 text-gray-500">
              <Gamepad size="h-10 w-10" />
            </div>
          </div>
          <div class="flex flex-col">
            <h3 class="text-lg font-semibold text-gray-500">Games count:</h3>
            <span class="text-lg text-center font-bold">{response.count}</span>
          </div>
        </div>
      </div>
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row h-full justify-center items-center p-2">
          <a
            class="text-white rounded-md px-4 py-2 text-center w-full lg:w-auto text-sm font-medium bg-primary hover:bg-accent hover:text-black transition ease-in duration-200"
            href="/admin/add/game"
            use:link>Add new game</a
          >
        </div>
      </div>
    </div>

    <div
      class="relative overflow-x-auto bg-white border border-gray-200 shadow-sm sm:rounded-lg"
    >
      <GamesTables
        on:deleteGame={handleDeleteGame}
        games={response.gameConfigs}
      />
    </div>
  {:catch error}
    <DangerAlert alertMessage={error.message} alertDescription={''} />
  {/await}
</div>
