<script lang="ts">
  import { onMount } from 'svelte';

  import type { ISessionSchedule } from '../../interfaces/ISessionSchedule';
  import Input from '../../lib/components/input/Input.svelte';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import ButtonUi from '../../lib/components/paginator/ButtonUI.svelte';
  import { ToastNotification } from '../../lib/services/toast';
  import {
    createSessionSchedule,
    getSessionSchedule,
    updateSessionSchedule,
  } from '../../lib/services/together/together.api';

  export let englishExperienceOptions: { value: string; text: string }[] = [];
  export let spanishExperienceOptions: { value: string; text: string }[] = [];

  let scheduleSessions: Record<string, ISessionSchedule | null> = {};
  let selectedEnglishExperienceIds: Record<string, string> = {};
  let selectedSpanishExperienceIds: Record<string, string> = {};
  let suggestedDates: Record<string, string | undefined> = {};
  let isLoading = true;

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ] as const;

  const handleSubmit = async (month: string) => {
    const payload = {
      eventName: month,
      englishExperienceId: selectedEnglishExperienceIds[month]
        ? +selectedEnglishExperienceIds[month]
        : undefined,
      spanishExperienceId: selectedSpanishExperienceIds[month]
        ? +selectedSpanishExperienceIds[month]
        : undefined,
      suggestedDate: suggestedDates[month]
        ? new Date(suggestedDates[month])
        : undefined,
    };

    try {
      if (!scheduleSessions[month]) {
        scheduleSessions[month] = await createSessionSchedule(payload);
        ToastNotification.success(`Session schedule created successfully`);
      } else {
        await updateSessionSchedule(scheduleSessions[month]!.id, payload);
        ToastNotification.success(`Session schedule updated successfully`);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || error?.message || 'An error occurred';
      ToastNotification.error(errorMessage);
    }
  };

  onMount(async () => {
    const promises = months.map(async (month) => {
      try {
        const result = await getSessionSchedule(month);
        scheduleSessions[month] = result;

        selectedEnglishExperienceIds[month] = String(
          result.englishExperience?.id || '',
        );
        selectedSpanishExperienceIds[month] = String(
          result.spanishExperience?.id || '',
        );
        suggestedDates[month] = result.suggestedDate
          ? new Date(result.suggestedDate).toISOString().split('T')[0]
          : undefined;
      } catch (error) {
        scheduleSessions[month] = null;
        selectedEnglishExperienceIds[month] = '';
        selectedSpanishExperienceIds[month] = '';
        suggestedDates[month] = undefined;
      }
    });

    await Promise.allSettled(promises);
    isLoading = false;
  });
</script>

{#if isLoading}
  <Loading loadingSize="h-20 w-20" />
{:else}
  <table>
    <thead>
      <tr class="text-left">
        <th class="p-2">Month</th>
        <th class="p-2">English experience</th>
        <th class="p-2">Spanish experience</th>
        <th class="p-2">Suggested date</th>
      </tr>
    </thead>
    <tbody>
      {#each months as month}
        <tr>
          <td>{month}</td>
          <td class="p-2">
            <Input
              testId={`english-experience-${month}`}
              type="select"
              bind:value={selectedEnglishExperienceIds[month]}
              options={englishExperienceOptions}
            />
          </td>
          <td class="p-2">
            <Input
              testId={`spanish-experience-${month}`}
              type="select"
              bind:value={selectedSpanishExperienceIds[month]}
              options={spanishExperienceOptions}
            />
          </td>
          <td class="p-2">
            <Input
              testId={`suggested-date-${month}`}
              type="date"
              bind:value={suggestedDates[month]}
            />
          </td>
          <td>
            <ButtonUi
              testId={`save-${month}`}
              on:click={() => handleSubmit(month)}
            >
              Save
            </ButtonUi>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
{/if}
