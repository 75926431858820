<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { link } from 'svelte-navigator';

  import { ToastNotification } from '../../../lib/services/toast';
  import type { IMiniGameConfig } from '../../../routes/mini-games/IMiniGamesResponse';

  export let miniGames: IMiniGameConfig[];
  let baseUrl = import.meta.env['VITE_CLIENT_BASE_URL'] + `/mini-games/`;
  const dispatch = createEventDispatcher();
  const handleCopyDicesLink = async (miniGame: IMiniGameConfig) => {
    await navigator.clipboard.writeText(
      `${baseUrl}${miniGame.gameName}/${miniGame.id}`,
    );
    ToastNotification.success('Link copied to clipboard');
  };
</script>

<table class="w-full text-sm text-left text-gray-500">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th scope="col" class="px-6 py-3">Id</th>
      <th scope="col" class="px-6 py-3">Minigame name</th>
      <th scope="col" class="px-6 py-3">Title</th>
      <th scope="col" class="px-6 py-3">description</th>
      <th scope="col" class="px-6 py-3">Action</th>
    </tr>
  </thead>
  <tbody>
    {#each miniGames as game}
      <tr class="bg-white border-b">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900"
          >{game.id}</th
        >
        <td class="px-5 py-3">{game.gameName}</td>
        <td class="px-5 py-3 max-w-[200px] truncate">{game.title}</td>
        <td class="px-5 py-3 max-w-[200px] truncate">{game.description}</td>
        <td class="px-5 py-3 flex flex-wrap gap-2">
          <a
            href={`/admin/mini-game/${game.id}`}
            use:link
            class="font-medium text-primary hover:underline">View</a
          >
          <a
            href={`/admin/edit/mini-game/${game.id}`}
            use:link
            class="font-medium text-primary hover:underline">Edit</a
          >
          <button
            on:click={() =>
              dispatch('deleteGame', {
                gameId: game.id,
                gameTitle: game.gameName,
              })}
            class="font-medium text-primary hover:underline">Delete</button
          >
          <button
            on:click={() => handleCopyDicesLink(game)}
            class="font-medium text-primary hover:underline">Copy url</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>
