import type { ObjectSchema, ValidationResult } from 'joi';

export const validateDynamicForm = <T extends Record<string, any>, U>(
  gameForm: T,
  validationInputs: U,
  formSchema: ObjectSchema,
  fieldResourceName?: keyof T,
) => {
  const validation: ValidationResult = formSchema.validate(validationInputs, {
    abortEarly: false,
  });

  const fieldResource = gameForm[fieldResourceName] as Record<
    string,
    { value: string | number; error: string }
  >;

  for (const key in gameForm) {
    if (key !== fieldResourceName) {
      gameForm[key as keyof T].error = '';
    }
  }

  validation.error?.details.forEach((error) => {
    const fieldName = error.context?.key;
    if (fieldName && fieldName in fieldResource) {
      fieldResource[fieldName].error = error.message;
    } else if (fieldName && fieldName in gameForm) {
      gameForm[fieldName as keyof T].error = error.message;
    }
  });

  return gameForm;
};

export const validateNormalForm = <T extends Record<string, any>, U>(
  gameForm: T,
  validationInputs: U,
  validationSchema: ObjectSchema,
) => {
  const validation = validationSchema.validate(validationInputs, {
    abortEarly: false,
  });

  for (const key in gameForm) {
    gameForm[key as keyof T].error = '';
  }

  validation.error?.details.forEach((error) => {
    const fieldName = error.context?.key as keyof T;
    gameForm[fieldName].error = error.message;
  });

  return gameForm;
};
