<script lang="ts">
  import { del } from '../../../lib/services/api-axios';
  import { ToastNotification } from '../../services/toast';
  import clickOutside from '../helpers/clickOutside';

  export let toastId = 0;
  export let title = '';
  export let resourceTitle: string;
  export let resourceId: number;
  export let resourceName: string;
  export let resourceUrlPath: string;

  let isDisabled = false;

  const handleDeleteResource = async () => {
    isDisabled = true;
    ToastNotification.removeToasts();
    try {
      await del(`/${resourceUrlPath}/${resourceId}`);

      ToastNotification.removeToastById(toastId);

      ToastNotification.success(
        `The ${resourceName} has been deleted successfully!`,
      );
    } catch (error) {
      ToastNotification.removeToastById(toastId);
      ToastNotification.error(error?.response?.data?.message ?? error?.message);
    } finally {
      isDisabled = false;
    }
  };

  const handleDeclineButton = () => {
    ToastNotification.removeToasts();
    ToastNotification.removeToastById(toastId);
    ToastNotification.customizable({
      msg: 'Declined!',
      theme: {
        '--toastBackground': '#F56565',
        '--toastBarBackground': '#C53030',
      },
    });
  };

  const handleCloseToast = () => {
    ToastNotification.removeToastById(toastId);
  };
</script>

<div
  class="relative flex flex-wrap justify-center items-center right-0 left-0"
  use:clickOutside={handleCloseToast}
>
  <div class="w-full h-32 bg-gray-600 flex flex-col p-2">
    <div class="h-6 flex flex-row items-center mb-1">
      <h1 class="font-bold text-center text-lg">{title}</h1>
    </div>
    <div>
      <p class="flex-1 text-md text-center">
        Are you sure you want to permanently delete the "{resourceTitle}" {resourceName}?
      </p>
    </div>
    <div class="h-12 mt-auto flex flex-row gap-6 justify-around">
      <button
        class={`bg-transparent hover:bg-accent/70 text-white w-full font-bold py-1 px-3 rounded hover:text-black transition ease-in duration-150
        ${isDisabled ? 'bg-white/50' : ''}`}
        disabled={isDisabled}
        data-cy="decline-button"
        on:click={handleDeclineButton}>No</button
      >
      <button
        class={`bg-transparent hover:bg-red-500/70 text-white w-full font-bold py-1 px-3 rounded hover:text-black transition ease-in duration-150
        ${isDisabled ? 'bg-white/50' : ''}`}
        disabled={isDisabled}
        data-cy="confirm-button"
        on:click={handleDeleteResource}>Yes</button
      >
    </div>
  </div>
</div>
