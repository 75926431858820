import type Joi from 'joi';

export const validateField = <T>(
  gameForm: T,
  fieldName: string,
  formSchema: Joi.ObjectSchema,
) => {
  const fieldSchema = formSchema.extract(fieldName);

  const { error } = fieldSchema.validate(gameForm[fieldName].value, {
    abortEarly: false,
  });

  gameForm[fieldName].error = error?.details[0]?.message || '';

  return gameForm;
};
