<script lang="ts">
  import { onMount } from 'svelte';
  import { navigate } from 'svelte-navigator';

  import {
    ExperienceLanguages,
    type ICategory,
    type IExperience,
    SubscriptionPlan,
  } from '../../interfaces/IExperience';
  import { validateField } from '../../lib/components/games-forms/validateField';
  import { validateNormalForm } from '../../lib/components/games-forms/validateForm';
  import Input from '../../lib/components/input/Input.svelte';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import { get } from '../../lib/services/api-axios';
  import { ToastNotification } from '../../lib/services/toast';
  import {
    createExperience,
    getAllCategories,
    updateExperience,
    uploadFile,
    uploadImage,
  } from '../../lib/services/together/together.api';
  import type { IExperienceForm } from './IExperienceForm';
  import type { IExperienceValidationInputs } from './IExperienceValidationInputs';
  import {
    createExperienceSuccestfulResponse,
    updateExperienceSuccestfulResponse,
  } from './constants';
  import { experienceSchema } from './experience.validation.schema';

  export let id: number | undefined = undefined;

  let isEditing = false;
  let isLoading = false;

  let validationInputs: IExperienceValidationInputs;

  let categoryOptions: ICategory[] = [];
  const loadCategories = async () => {
    try {
      const { categories } = await getAllCategories();
      categoryOptions = categories;
    } catch (error) {
      ToastNotification.error('Failed to load categories');
    }
  };

  let experienceForm: IExperienceForm & { [key: string]: any } = {
    id: { value: '', error: '' },
    name: { value: '', error: '' },
    description: { value: '', error: '' },
    instructions: { value: '', error: '' },
    templateUrl: { value: '', error: '' },
    imageUrl: { value: '', error: '' },
    documentUrl: { value: '', error: '' },
    customMessage: { value: '', error: '' },
    allowInPlan: { value: SubscriptionPlan.PRO, error: '' },
    durationInMinutes: { value: 0, error: '' },
    category: { value: 0, error: '' },
    language: { value: ExperienceLanguages.english, error: '' },
    minParticipants: { value: 0, error: '' },
    maxParticipants: { value: 0, error: '' },
  };

  let inputs = [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Experience name',
      type: 'text',
      value: experienceForm.name.value,
      error: experienceForm.name.error,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      placeholder: 'Experience description',
      value: experienceForm.description.value,
      error: experienceForm.description.error,
      rows: 3,
    },
    {
      name: 'instructions',
      label: 'Instructions',
      type: 'textarea',
      placeholder: 'Instructions',
      value: experienceForm.instructions.value,
      error: experienceForm.instructions.error,
      rows: 5,
    },
    {
      name: 'templateUrl',
      label: 'Template url',
      type: 'text',
      placeholder: 'Experience template url',
      value: experienceForm.templateUrl.value,
      error: experienceForm.templateUrl.error,
    },
    {
      name: 'durationInMinutes',
      label: 'Duration in minutes',
      type: 'number',
      value: experienceForm.category.value,
      error: experienceForm.category.error,
    },
    {
      name: 'customMessage',
      label: 'Custom message (optional)',
      type: 'textarea',
      placeholder: 'Custom message',
      value: experienceForm.customMessage.value,
      error: experienceForm.customMessage.error,
      rows: 5,
    },
    {
      name: 'category',
      label: 'Category',
      type: 'select',
      placeholder: 'Experience category',
      options: [],
      value: experienceForm.category.value,
      error: experienceForm.category.error,
    },
    {
      name: 'allowInPlan',
      label: 'Plan tier',
      type: 'select',
      placeholder: 'Plan tiers',
      options: [
        { value: SubscriptionPlan.FREE, text: 'Free' },
        { value: SubscriptionPlan.PRO, text: 'Pro' },
      ].map((option) => ({
        value: option.value,
        text: option.text,
      })),
      value: experienceForm.category.value,
      error: experienceForm.category.error,
    },
    {
      name: 'language',
      label: 'Language',
      type: 'select',
      placeholder: 'Experience language',
      options: [
        { value: ExperienceLanguages.english, text: 'English' },
        { value: ExperienceLanguages.spanish, text: 'Spanish' },
      ].map((option) => ({
        value: option.value,
        text: option.text,
      })),
      value: experienceForm.language.value,
      error: experienceForm.language.error,
    },
    {
      name: 'minParticipants',
      label: 'Min participants',
      type: 'number',
      value: experienceForm.minParticipants.value,
      error: experienceForm.minParticipants.error,
      min: 2,
    },
    {
      name: 'maxParticipants',
      label: 'Max participants',
      type: 'number',
      value: experienceForm.maxParticipants.value,
      error: experienceForm.maxParticipants.error,
      min: 2,
    },
  ];

  const validateFormField = (fieldName: string) => {
    experienceForm = validateField(experienceForm, fieldName, experienceSchema);
  };

  const getExperienceById = async () => {
    const experience = await get<IExperience>(`/experiences/${id}`);

    experienceForm.name.value = experience.name;
    experienceForm.description.value = experience.description;
    experienceForm.instructions.value = experience.instructions;
    experienceForm.templateUrl.value = experience.templateUrl;
    experienceForm.imageUrl.value = experience.imageUrl;
    experienceForm.documentUrl.value = experience.documentUrl;
    experienceForm.allowInPlan.value = experience.allowInPlan;
    experienceForm.category.value = experience.category.id;
    experienceForm.language.value = experience.language;
    experienceForm.customMessage.value = experience.customMessage;
    experienceForm.durationInMinutes.value = experience.durationInMinutes;
    experienceForm.minParticipants.value = experience.minParticipants;
    experienceForm.maxParticipants.value = experience.maxParticipants;
    return experience;
  };
  let imageFile: File | null = null;
  const handleSubmit = async () => {
    const validationForm = validateNormalForm<
      IExperienceForm,
      IExperienceValidationInputs
    >(experienceForm, validationInputs, experienceSchema);
    experienceForm = validationForm;

    if (!imageFile && experienceForm.imageUrl.value === '') {
      ToastNotification.error('Please select an image');
      return;
    } else {
      experienceForm.imageUrl.error = '';
    }

    try {
      isLoading = true;
      if (imageFile && experienceForm.imageUrl.value === '') {
        const imageUrl = await uploadImage(imageFile);
        experienceForm.imageUrl.value = imageUrl;
        validationInputs.imageUrl = imageUrl;
      }

      if (documentFile) {
        const documentUrl = await uploadFile(documentFile);
        experienceForm.documentUrl.value = documentUrl;
      }
      for (const key in experienceForm) {
        if (experienceForm[key as keyof IExperienceForm].error) return;
      }

      const payload = {
        name: experienceForm.name.value.trim(),
        description: experienceForm.description.value.trim(),
        instructions: experienceForm.instructions.value.trim(),
        durationInMinutes: experienceForm.durationInMinutes.value,
        templateUrl: experienceForm.templateUrl.value.trim(),
        documentUrl: experienceForm.documentUrl.value.trim(),
        customMessage: experienceForm.customMessage.value.trim(),
        allowInPlan: experienceForm.allowInPlan.value,
        imageUrl: experienceForm.imageUrl.value.trim(),
        category: categoryOptions.find(
          (category) => category.id === experienceForm.category.value,
        ),
        language: experienceForm.language.value as ExperienceLanguages,
        minParticipants: experienceForm.minParticipants.value,
        maxParticipants: experienceForm.maxParticipants.value,
      };

      if (isEditing && id) {
        await updateExperience({ ...payload, id });
        ToastNotification.success(updateExperienceSuccestfulResponse);
      } else {
        console.log(payload);
        await createExperience(payload);
        ToastNotification.success(createExperienceSuccestfulResponse);
      }
      navigate('/admin/experiences');
    } catch (error) {
      ToastNotification.error(error?.response?.data?.message ?? error?.message);
    } finally {
      isLoading = false;
    }
  };

  $: validationInputs = {
    name: experienceForm.name.value,
    description: experienceForm.description.value,
    instructions: experienceForm.instructions.value,
    templateUrl: experienceForm.templateUrl.value,
    imageUrl: experienceForm.imageUrl.value,
    documentUrl: experienceForm.documentUrl.value,
    customMessage: experienceForm.customMessage.value,
    allowInPlan: experienceForm.allowInPlan.value,
    durationInMinutes: experienceForm.durationInMinutes.value,
    category: experienceForm.category.value,
    language: experienceForm.language.value,
    minParticipants: experienceForm.minParticipants.value,
    maxParticipants: experienceForm.maxParticipants.value,
  };

  $: {
    if (id === undefined) {
      isEditing = false;
    } else {
      isEditing = true;
      getExperienceById();
    }
  }
  let imgPreview: string;
  const handlePreview = async (e: Event) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      experienceForm.imageUrl.value = '';
      imgPreview = URL.createObjectURL(file);
      imageFile = file;
    }
  };
  let documentFile: File | null = null;
  const handleDocument = async (e: Event) => {
    const file = (e.target as HTMLInputElement).files?.[0];
    if (file) {
      documentFile = file;
    }
  };

  $: {
    const categoryInput = inputs.find((input) => input.name === 'category');
    if (categoryInput) {
      categoryInput.options = categoryOptions.map((category) => ({
        text: `${category.englishName} - ${category.spanishName}`,
        value: category.id,
      }));
      inputs = [...inputs];
    }
  }

  onMount(() => loadCategories());
</script>

<div class="flex flex-col gap-2 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm p-2"
  >
    <h3 class="text-2xl font-bold">
      {isEditing ? 'Edit a Experience' : 'Add new Experience'}
    </h3>
  </div>

  <div
    class="flex justify-center bg-white border border-gray-200 p-6 rounded-lg"
  >
    <form
      class="flex flex-col items-center gap-3 w-96"
      on:submit|preventDefault={handleSubmit}
    >
      {#each inputs as inputField}
        <Input
          styleClass="w-full"
          on:change={() => validateFormField(inputField.name)}
          bind:value={experienceForm[inputField.name].value}
          bind:error={experienceForm[inputField.name].error}
          type={inputField.type}
          placeholder={inputField.placeholder}
          label={inputField.label}
          name={inputField.name}
          rows={inputField.type === 'textarea' ? inputField.rows : undefined}
          options={inputField.type === 'select' ? inputField.options : []}
        />
        {#if inputField.name === 'templateUrl'}
          <div class="w-full h-fit flex flex-row mt-1 items-center">
            <div class="w-full flex flex-col gap-2 h-full">
              <label
                for="image"
                class="text-sm w-full font-medium text-gray-900">Image</label
              >
              <input
                data-cy="image-input"
                name="image"
                class="w-full"
                type="file"
                on:change={handlePreview}
                accept="image/*"
              />
            </div>

            {#if experienceForm.imageUrl.value !== '' || imgPreview}
              <img
                src={experienceForm.imageUrl.value || imgPreview}
                alt="experience thumbmail"
                class="w-24 h-20 object-cover"
              />
            {:else}
              <div class="flex flex-col justify-center items-center h-full">
                <span class="text-sm font-medium text-gray-900">No image</span>
              </div>
            {/if}
          </div>
          <label for="document" class="text-sm w-full font-medium text-gray-900"
            >Asociated document (optional)</label
          >
          <input
            data-cy="document-input"
            name="document"
            class="w-full"
            type="file"
            on:change={handleDocument}
            accept=".doc, .docx, .pdf"
          />
          {#if isEditing && experienceForm.documentUrl.value}
            <span class="text-sm w-full font-medium text-gray-900"
              >Current document:</span
            >
            <span
              >Current document: {experienceForm.documentUrl.value &&
                experienceForm.documentUrl.value.split('/').pop()}</span
            >
          {/if}
        {/if}
      {/each}
      <button
        class={`bg-primary hover:bg-accent/70 text-white w-full h-full font-bold mt-4 py-3 px-4 rounded hover:text-black transition ease-in duration-150
      ${isLoading ? 'cursor-not-allowed bg-accent/70' : ''}`}
        type="submit"
        data-cy="submit-experience-button"
        disabled={isLoading}
      >
        {#if isLoading}
          <Loading loadingColor="text-white" loadingSize="h-6 w-6" />
        {:else if isEditing}
          Update Experience
        {:else}
          Create Experience
        {/if}
      </button>
    </form>
  </div>
</div>
