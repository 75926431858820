import {
  AuthenticationDetails,
  CognitoUser,
  type CognitoUserPool,
  type CognitoUserSession,
} from 'amazon-cognito-identity-js';

export const loginWithCredentials = (
  email: string,
  password: string,
  userPool: CognitoUserPool,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const authentication = {
      Username: email,
      Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authentication);

    const user = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(user);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result: CognitoUserSession) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
};

export const refreshUserToken = async (
  userPool: CognitoUserPool,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (!user) return reject('User is not logged in');

    user.getSession((_: Error, session: CognitoUserSession | null) => {
      if (!session) return reject("User session doesn't exist");
      resolve(session);
    });
  });
};
