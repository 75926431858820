<script lang="ts">
  import type { IPhase } from 'src/interfaces/IPhase';
  import { slide } from 'svelte/transition';

  import { validateField } from '../../lib/components/games-forms/validateField';
  import { change } from '../../lib/components/helpers/changeDate';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import { del, get, post } from '../../lib/services/api-axios';
  import { ToastNotification } from '../../lib/services/toast';
  import type { IBoxesReponse } from '../boxes/IBoxesResponse';
  import type { IGameConfig, IGamesResponse } from '../games/IGamesResponse';
  import type { IBoxByIdResponse } from '../view-box/IBoxByIdResponse';
  import type { IGameBoxForm } from './IGameBoxRelation';
  import {
    addGameToBoxSuccestfulResponse,
    deleteBoxFromGameSuccestfulResponse,
  } from './constants';
  import { FormOperation } from './formOperation.enum';
  import { gameBoxSchema } from './gameBox.validation.schema';

  let isLoading = false;
  let isLoadingBox = false;
  let isLoadingGames = false;

  let games: IGameConfig[] = [];
  let selectedBox: IBoxByIdResponse;
  let selectedBoxGamePhases: IPhase[];

  let gameBoxForm: IGameBoxForm & { [key: string]: any } = {
    box: { value: null, error: '' },
    warmUp: { value: null, error: '' },
    play: { value: null, error: '' },
    connect: { value: null, error: '' },
    memory: { value: null, error: '' },
  };

  let boxSelect = [
    {
      name: 'box',
      label: 'Box',
      placeholder: 'This is box description',
      value: gameBoxForm.box.value,
      error: gameBoxForm.box.error,
    },
  ];

  let phasesSelect = [
    {
      name: 'warmUp',
      label: 'Warm Up',
      placeholder: 'This is box description',
      value: gameBoxForm.warmUp.value,
      error: gameBoxForm.warmUp.error,
    },
    {
      name: 'play',
      label: 'Play',
      placeholder: 'This is box description',
      value: gameBoxForm.play.value,
      error: gameBoxForm.play.error,
    },

    {
      name: 'connect',
      label: 'Connect',
      placeholder: 'This is box description',
      value: gameBoxForm.connect.value,
      error: gameBoxForm.connect.error,
    },

    {
      name: 'memory',
      label: 'Memory',
      placeholder: 'This is box description',
      value: gameBoxForm.memory.value,
      error: gameBoxForm.memory.error,
    },
  ];

  const getBoxes = async () => {
    return await get<IBoxesReponse>('/box');
  };

  const getGames = async () => {
    try {
      isLoadingGames = true;
      const responseGames = await get<IGamesResponse>('/game-config');
      games = responseGames.gameConfigs;
    } catch (error) {
      ToastNotification.error(error?.response?.data?.message ?? error?.message);
    } finally {
      isLoadingGames = false;
    }
  };

  const handleBoxChange = async (event: {
    currentTarget: HTMLSelectElement;
  }) => {
    const selectedBoxIdEvent = event.currentTarget.value;

    selectedBox = null;
    selectedBoxGamePhases = null;
    for (const key in gameBoxForm) {
      if (key !== 'box' && key in gameBoxForm) {
        gameBoxForm[key].value = null;
      }
    }

    if (selectedBoxIdEvent == 'null') return;
    isLoadingBox = true;

    try {
      const boxById = await get<IBoxByIdResponse>(
        `/box/${gameBoxForm.box.value}`,
      );

      selectedBox = boxById;
      selectedBoxGamePhases = boxById.phases;
      handleBoxPhases(selectedBoxGamePhases);
    } catch (error) {
      ToastNotification.error(error?.response?.data?.message ?? error?.message);
    } finally {
      isLoadingBox = false;
    }
  };

  const handleBoxPhases = (phases: IPhase[]) => {
    const gamePhases: Record<string, string> = {
      'Warm Up': 'warmUp',
      Play: 'play',
      Connect: 'connect',
      Memory: 'memory',
    };

    phases.forEach((phase: IPhase) => {
      let selectedPhase = gamePhases[phase.name];
      if (selectedPhase in gameBoxForm) {
        gameBoxForm[selectedPhase].value = phase.gameConfig.id;
      }
    });
  };

  const validateFormField = (fieldName: string) => {
    gameBoxForm = validateField(gameBoxForm, fieldName, gameBoxSchema);
  };

  const handleFormAddOrDelete = async (
    phaseField: string,
    phaseName: string,
    operation: string,
  ) => {
    const gameOrder: Record<string, number> = {
      warmUp: 1,
      play: 2,
      connect: 3,
      memory: 4,
    };

    let selectedOrder = gameOrder[phaseField];

    validateFormField('box');
    validateFormField(phaseField);

    for (const key in gameBoxForm) {
      if (gameBoxForm[key as keyof IGameBoxForm].error) return;
    }

    isLoading = true;

    try {
      const payload = {
        configId: Number(gameBoxForm[phaseField].value),
        phaseName,
        phaseOrder: Number(selectedOrder),
      };

      if (operation === FormOperation.add) {
        if (selectedBox.phases.find((phase) => phase.name === phaseName)) {
          ToastNotification.error(`This phase already exists in this box`);
          return;
        }

        const response = await post<IBoxByIdResponse>(
          `/box/${gameBoxForm.box.value}/game-config/`,
          payload,
        );

        selectedBox = response;
        ToastNotification.success(addGameToBoxSuccestfulResponse);
      }

      if (operation === FormOperation.delete) {
        const response = await del(
          `/box/${gameBoxForm.box.value}/game-config/`,
          payload,
        );
        selectedBox = response as IBoxByIdResponse;
        ToastNotification.success(deleteBoxFromGameSuccestfulResponse);
      }
    } catch (error) {
      ToastNotification.error(error?.response?.data?.message ?? error?.message);
    } finally {
      getGames();
      isLoading = false;
    }
  };

  const handleGameOptionDescription = (game: IGameConfig) => {
    return `ID: ${game.id} | ${game.gameName} | ${game.title} | ${
      game.phase?.id ? 'Already in a box' : ''
    }`;
  };

  getGames();
</script>

<div class="flex flex-col gap-2 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm p-2"
  >
    <h3 class="text-2xl font-bold">Add or delete game from box</h3>
  </div>

  <div
    class="flex justify-center bg-white border border-gray-200 p-6 rounded-lg"
  >
    <form class="flex flex-col items-center gap-3 w-96">
      <div class="w-full">
        {#each boxSelect as { label, name }}
          <label
            for="boxes"
            class="text-md mb-2 w-full font-medium text-gray-900"
          >
            {label}
          </label>

          <select
            id="boxes"
            bind:value={gameBoxForm[name].value}
            on:change={handleBoxChange}
            on:change={() => validateFormField(name)}
            class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
              ${gameBoxForm[name].error ? 'ring-red-500' : ''}`}
          >
            {#await getBoxes()}
              <option value={null}>Loading...</option>
            {:then response}
              <option value={null}>Choose a box</option>
              {#each response.boxes as option}
                <option value={option.id}>
                  {option.title}
                </option>
              {/each}
            {:catch error}
              <option value={null}>{error.message}</option>
            {/await}
          </select>

          {#if gameBoxForm[name].error}
            <span class="text-xs text-red-600 font-medium w-full">
              {gameBoxForm[name].error}
            </span>
          {/if}
        {/each}

        {#if isLoadingBox}
          <Loading
            loadingStyles="flex items-center my-2"
            loadingSize="h-10 w-10"
            loadingColor="text-primary"
          />
        {/if}

        {#if selectedBox}
          <dl
            class="max-w-md w-full border-2 my-2 bg-slate-50 border-gray-100 p-2 text-gray-900 divide-y divide-gray-200"
            transition:slide|local
          >
            <div class="flex flex-col pb-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Title</dt>
              <dd class="text-lg font-semibold">{selectedBox.title}</dd>
            </div>
            <div class="flex flex-col py-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Description</dt>
              <dd class="text-lg font-semibold">
                {selectedBox.description}
              </dd>
            </div>
            <div class="flex flex-col pt-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Create date</dt>
              <dd class="text-lg font-semibold">
                {change(selectedBox.createdAt)}
              </dd>
            </div>
            <div class="flex flex-col pt-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Update date</dt>
              <dd class="text-lg font-semibold">
                {change(selectedBox.updatedAt)}
              </dd>
            </div>
          </dl>
        {/if}
      </div>

      {#each phasesSelect as phase}
        <div class="flex flex-wrap sm:flex-nowrap justify-center w-full">
          <div class="w-full">
            <label
              for={`game-${phase.name}`}
              class="text-sm w-full font-medium text-gray-900"
            >
              Select {phase.label} Phase
            </label>

            <select
              id={`game-${phase.name}`}
              bind:value={gameBoxForm[phase.name].value}
              on:change={() => validateFormField(phase.name)}
              class={`w-full focus:outline-none rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600
               ${gameBoxForm[phase.name].error ? 'ring-red-500' : ''}`}
            >
              {#if isLoadingGames}
                <option value={null}>Loading...</option>
              {:else}
                <option value={null}>Choose a game</option>
              {/if}
              {#each games as game}
                <option value={game.id}>
                  {handleGameOptionDescription(game)}
                </option>
              {/each}
            </select>

            {#if gameBoxForm[phase.name].error}
              <span class="text-xs text-red-600 font-medium w-full">
                {gameBoxForm[phase.name].error}
              </span>
            {/if}
          </div>

          <div
            class="flex justify-center items-center m-auto mt-2 sm:mt-7 gap-1 mx-1"
          >
            <button
              class={`py-1 px-2 text-sm font-medium rounded-md text-center text-white bg-green-500 hover:bg-green-700 focus:ring-2 focus:outline-none focus:ring-green-800 transition ease-in duration-200 
              ${isLoading ? 'cursor-not-allowed bg-accent/70' : ''}`}
              data-cy={`add-${phase.name}`}
              type="button"
              on:click={() =>
                handleFormAddOrDelete(
                  phase.name,
                  phase.label,
                  FormOperation.add,
                )}
              disabled={isLoading}
            >
              {#if isLoading}
                <Loading
                  loadingColor="text-white"
                  loadingSize="h-5 w-5"
                  loadingMargin=""
                />
              {:else}
                Add
              {/if}
            </button>
            <button
              class={`py-1 px-2 text-sm font-medium rounded-md text-center text-white bg-red-500 hover:bg-red-700 focus:ring-2 focus:outline-none focus:ring-red-800 transition ease-in duration-200 
              ${isLoading ? 'cursor-not-allowed bg-accent/70' : ''}`}
              type="button"
              data-cy={`delete-${phase.name}`}
              on:click={() =>
                handleFormAddOrDelete(
                  phase.name,
                  phase.label,
                  FormOperation.delete,
                )}
              disabled={isLoading}
            >
              {#if isLoading}
                <Loading
                  loadingColor="text-white"
                  loadingSize="h-5 w-5"
                  loadingMargin=""
                />
              {:else}
                Delete
              {/if}
            </button>
          </div>
        </div>
      {/each}
    </form>
  </div>
</div>
