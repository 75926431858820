<script lang="ts">
  import type { IGameConfig } from '../../../routes/games/IGamesResponse';

  export let gameConfig: IGameConfig;
  export let gameName: string;

  let gameElementList: { text: string }[] = [];

  const gameOptions: Record<string, any> = {
    'Who is': {
      gameElementList:
        'whoIsCards' in gameConfig ? gameConfig.whoIsCards?.slice(1) : [],
    },
    'True or false': {
      gameElementList:
        'trueOrFalseTiles' in gameConfig ? gameConfig.trueOrFalseTiles : [],
    },
    'Double dice board': {
      gameElementList:
        'doubleDiceBoardTiles' in gameConfig
          ? gameConfig.doubleDiceBoardTiles
          : [],
    },
    'Written wishes': {
      gameElementList: null,
    },
  };

  const handleCurrentGameConfig = (gameName: string) => {
    const selectedOption = gameOptions[gameName] || {};
    gameElementList = selectedOption.gameElementList;
  };

  $: {
    handleCurrentGameConfig(gameName);
  }
</script>

{#if 'whoIsCards' in gameConfig}
  <div class="flex flex-col pt-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Initial Prompt</dt>
    <dd class="text-lg font-semibold">
      {gameConfig.whoIsCards[0].text}
    </dd>
  </div>
{:else if 'doubleDiceBoardMinRounds' in gameConfig}
  <div class="flex flex-col pt-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Min rounds</dt>
    <dd class="text-lg font-semibold">
      {gameConfig.doubleDiceBoardMinRounds}
    </dd>
  </div>
{:else if 'wishesAmount' in gameConfig}
  <div class="flex flex-col pt-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Wishes per player</dt>
    <dd class="text-lg font-semibold">
      {gameConfig.wishesAmount}
    </dd>
  </div>
{/if}
{#if gameElementList}
  <div class="flex flex-col py-3">
    <dt class="mb-1 text-gray-500 md:text-lg">
      {gameName === 'Who is' ? 'Cards' : 'Tiles'}
    </dt>

    <dd class="text-lg font-semibold">
      <ul class="list-decimal p-2">
        {#each gameElementList as element}
          <li class="mx-4">{element.text}</li>
        {/each}
      </ul>
    </dd>
  </div>
{/if}
