<script lang="ts">
  import type { IUser } from 'src/interfaces/IUser';

  export let users: IUser[];
</script>

<table class="w-full text-sm text-left text-gray-500">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th scope="col" class="px-6 py-3">Id</th>
      <th scope="col" class="px-6 py-3">Name</th>
      <th scope="col" class="px-6 py-3">Last Name</th>
      <th scope="col" class="px-6 py-3">Email</th>
      <th scope="col" class="px-6 py-3">Organization</th>
    </tr>
  </thead>
  <tbody>
    {#each users as { id, name, lastName, email, tenant }}
      <tr class="bg-white border-b">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900">
          {id}
        </th>
        <td class="px-5 py-3">{name}</td>
        <td class="px-5 py-3">{lastName}</td>
        <td class="px-5 py-3">{email}</td>
        <td class="px-5 py-3">{tenant?.name}</td>
      </tr>
    {/each}
  </tbody>
</table>
