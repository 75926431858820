<script lang="ts">
  import type { IPhase } from 'src/interfaces/IPhase';
  import { fade } from 'svelte/transition';

  import box from '../../assets/box-games.png';
  import DangerAlert from '../../lib/components/alerts/DangerAlert.svelte';
  import { change } from '../../lib/components/helpers/changeDate';
  import { get } from '../../lib/services/api-axios';
  import GameDetail from '../game-box-relation-form/GameDetail.svelte';
  import type { IGameConfig } from '../games/IGamesResponse';
  import type { IBoxByIdResponse } from './IBoxByIdResponse';

  export let id: string;

  let boxResponse: IBoxByIdResponse;

  let selectedGameIndex = 0;
  let selectedGame: IGameConfig;

  const changeNextGame = () => {
    selectedGameIndex = (selectedGameIndex + 1) % boxResponse.phases.length;
    selectedGame = boxResponse.phases[selectedGameIndex].gameConfig;
  };

  const changePreviousGame = () => {
    selectedGameIndex =
      (selectedGameIndex - 1 + boxResponse.phases.length) %
      boxResponse.phases.length;

    selectedGame = boxResponse.phases[selectedGameIndex].gameConfig;
  };

  const getBox = async () => {
    const response = await get<IBoxByIdResponse>(`/box/${id}`);
    response.phases.sort((a: IPhase, b: IPhase) => a.order - b.order);
    selectedGame = response.phases[selectedGameIndex]?.gameConfig;

    boxResponse = response;

    return response;
  };
</script>

<div
  class="flex flex-col items-center gap-2 px-4 md:px-10 mx-auto w-full min-h-screen p-4"
  in:fade={{ duration: 250 }}
>
  <div
    class="flex items-center max-w-lg justify-center w-full bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-2xl p-2 text-black font-bold">Box detail</h3>
  </div>

  {#await getBox() then response}
    <div
      class="flex flex-col max-w-lg w-full gap-4 items-center justify-center"
    >
      <div
        class="flex flex-col w-full bg-white border border-gray-200 rounded-lg shadow-sm p-2 gap-2"
      >
        <div class="text-center">
          <h3 class="text-2xl p-2 text-black/70 font-semibold">
            Title: <strong class="text-black">{response.title}</strong>
          </h3>
        </div>
        <div class="flex justify-center">
          <img class="h-[10rem] w-[10rem]" src={box} alt={response.title} />
        </div>

        <div class="border-t-2 h-full w-full border-gray-300">
          <dl class="h-full w-full text-gray-900 divide-y divide-gray-200">
            <div class="flex flex-col pb-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Description</dt>
              <dd class="text-lg font-semibold">{response.description}</dd>
            </div>
            <div class="flex flex-col pt-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Max players</dt>
              <dd class="text-lg font-semibold">
                {response.maxPlayers}
              </dd>
            </div>
            <div class="flex flex-col pt-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Type</dt>
              <dd class="text-lg font-semibold">
                {response.webType}
              </dd>
            </div>
            <div class="flex flex-col pt-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Create date</dt>
              <dd class="text-lg font-semibold">
                {change(response.createdAt)}
              </dd>
            </div>

            <div class="flex flex-col pt-3">
              <dt class="mb-1 text-gray-500 md:text-lg">Update date</dt>
              <dd class="text-lg font-semibold">
                {change(response.updatedAt)}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {#if response.phases.length > 0}
        <div
          class="flex flex-col mb-4 w-full bg-white border border-gray-200 rounded-lg shadow-sm p-2 gap-2"
        >
          <div class="flex flex-col gap-1">
            <h3 class="text-center text-lg font-semibold">
              Phase: {boxResponse.phases[selectedGameIndex]
                ? boxResponse.phases[selectedGameIndex].name
                : ''}
            </h3>
          </div>
          {#if selectedGame}
            <GameDetail
              gameConfig={selectedGame}
              gameName={selectedGame.gameName}
              backgroundColor="bg-transparent"
              gameDetailStyles="w-full"
              borderColor="border-transparent"
            />

            <div class="flex gap-1">
              <button
                class="bg-primary hover:bg-accent/70 text-white w-full h-full font-bold py-2 px-3 rounded hover:text-black transition ease-in duration-150"
                on:click={changePreviousGame}
              >
                Previous
              </button>
              <span
                class="bg-accent text-white h-full font-bold py-2 px-4 rounded hover:text-black transition ease-in duration-150"
              >
                {selectedGameIndex + 1}
              </span>
              <button
                class="bg-primary hover:bg-accent/70 text-white w-full h-full font-bold py-2 px-3 rounded hover:text-black transition ease-in duration-150"
                on:click={changeNextGame}
              >
                Next
              </button>
            </div>
          {/if}
        </div>
      {/if}
    </div>
  {:catch error}
    <DangerAlert alertMessage={`${error.message}`} />
  {/await}
</div>
