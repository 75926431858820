<script lang="ts">
  import Loading from '../loading/Loading.svelte';

  export let isLoading = false;
  export let disabled = false;
  export let testId = '';
  export let text = 'Button';
  export let type: 'button' | 'submit' | 'reset' = undefined;

  const finalDisabled = disabled || isLoading;
</script>

<button
  class={`bg-primary hover:bg-accent/70 text-white w-full h-full font-bold mt-4 py-3 px-4 rounded 
      transition ease-in duration-150 
      ${isLoading ? 'cursor-not-allowed bg-accent/70' : ''} 
      ${finalDisabled ? 'hover:text-white' : 'hover:text-black'}`}
  type={type}
  data-cy={testId}
  disabled={finalDisabled}
>
  {#if isLoading}
    <Loading loadingColor="text-white" loadingSize="h-6 w-6" />
  {:else}
    {text}
  {/if}
</button>
