<script lang="ts">
  import { ToastNotification } from '../..//lib/services/toast';
  import CardText from '../../assets/svg/CardText.svelte';
  import DangerAlert from '../../lib/components/alerts/DangerAlert.svelte';
  import SubmissionsTable from '../../lib/components/tables/SubmissionsTable.svelte';
  import { get, patch } from '../../lib/services/api-axios';
  import type { ISubmissionsResponse } from './ISubmissionsResponse';

  const getSubmissions = async (): Promise<ISubmissionsResponse> => {
    return await get<ISubmissionsResponse>('/onboarding/submissions');
  };

  let submissionsPromise: Promise<ISubmissionsResponse> = getSubmissions();

  const handleApproveSubmission = async (event: CustomEvent) => {
    try {
      await patch(
        `/onboarding/submissions/${event.detail.submissionId}/approve`,
      );
      submissionsPromise = getSubmissions();
      ToastNotification.success('Successfully approved submission');
    } catch (error) {
      ToastNotification.error('Failed to approve submission');
    }
  };

  const handleRejectSubmission = async (event: CustomEvent) => {
    try {
      await patch(
        `/onboarding/submissions/${event.detail.submissionId}/reject`,
      );
      submissionsPromise = getSubmissions();
      ToastNotification.success('Successfully rejected submission');
    } catch {
      ToastNotification.error('Failed to reject submission');
    }
  };
</script>

<div class="flex flex-col gap-4 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-3xl p-2 font-bold">Pending submissions to be reviewed</h3>
  </div>

  {#await submissionsPromise}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full animate-pulse"
    >
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-100 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex items-center" />
          <div class="flex flex-col" />
        </div>
      </div>
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-100 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row justify-center items-center p-2" />
      </div>
    </div>
    <div
      class="relative overflow-x-auto h-48 bg-white border border-gray-100 shadow-sm sm:rounded-lg animate-pulse"
    />
  {:then response}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full"
    >
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex justify-start items-start">
            <div class="bg-gray-200 rounded-full p-3 text-gray-500">
              <CardText size="h-10 w-10" />
            </div>
          </div>
          <div class="flex flex-col">
            <h3 class="text-lg font-semibold text-gray-500">
              Pending submissions:
            </h3>
            <span class="text-lg text-center font-bold">{response.count}</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="relative overflow-x-auto bg-white border border-gray-200 shadow-sm sm:rounded-lg"
    >
      <SubmissionsTable
        on:approveSubmission={handleApproveSubmission}
        on:rejectSubmission={handleRejectSubmission}
        submissions={response.submissions}
      />
    </div>
  {:catch error}
    <DangerAlert alertMessage={error.message} alertDescription={''} />
  {/await}
</div>
