<script lang="ts">
  import type { SvelteComponent } from 'svelte';
  import { slide } from 'svelte/transition';

  import Loading from '../../lib/components/loading/Loading.svelte';
  import { get } from '../../lib/services/api-axios';
  import type { IGameConfig } from '../games/IGamesResponse';

  export let id: number | undefined;

  const menuForm = [
    {
      name: 'Who is',
    },
    {
      name: 'True or false',
    },
    {
      name: 'Double dice board',
    },
    {
      name: 'Written wishes',
    },
  ];

  let formComponent: typeof SvelteComponent;
  let selectedGame: string;
  let isEditing = false;

  const getGameById = async () => {
    const gameById = await get<IGameConfig>(`/game-config/${id}`);
    selectedGame = gameById.gameName;
    return gameById;
  };

  let gameConfigTypesForms = {
    'Who is': 'WhoIsForm',
    'True or false': 'TrueOrFalseForm',
    'Double dice board': 'DoubleDiceBoardForm',
    'Written wishes': 'WrittenWishesForm',
  };

  const loadGameFormComponent = async (gameName: string) => {
    let selectedGameType = gameConfigTypesForms[gameName];
    if (selectedGameType) {
      const componentModule = await import(
        `../../lib/components/games-forms/${selectedGameType}.svelte`
      );
      formComponent = componentModule.default;
    }
  };

  $: if (id) isEditing = true;
</script>

<div class="flex flex-col gap-2 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm p-2"
  >
    <h3 class="text-2xl font-bold">
      {id ? 'Edit a game' : 'Add new game'}
    </h3>
  </div>

  <div
    class="flex justify-center bg-white border border-gray-200 p-6 rounded-lg"
  >
    <div class="flex flex-col items-center gap-3 w-96">
      <div class="w-full">
        <label for="games" class="text-sm w-full font-medium text-gray-900">
          Select a game
        </label>
        <select
          id="games"
          bind:value={selectedGame}
          class="w-full focus:outline-none rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600"
          disabled={isEditing}
        >
          <option>Choose a game</option>
          {#each menuForm as option}
            <option value={option.name}>
              {option.name}
            </option>
          {/each}
        </select>
      </div>

      {#if id === undefined}
        {#await loadGameFormComponent(selectedGame) then}
          <div class="w-full" in:slide|local>
            <svelte:component
              this={formComponent}
              isEditing={false}
              gameConfig={null}
            />
          </div>
        {/await}
      {:else}
        {#await getGameById()}
          <div><Loading /></div>
        {:then response}
          <div class="w-full" in:slide|local>
            {#await loadGameFormComponent(selectedGame) then}
              <svelte:component
                this={formComponent}
                isEditing={true}
                gameConfig={response}
              />
            {/await}
          </div>
        {/await}
      {/if}
    </div>
  </div>
</div>
