export interface IMiniGameConfig {
  id: number;
  gameName: MiniGameNamesEnum;
  title: string;
  description: string;
  content: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}

export enum MiniGameNamesEnum {
  LuckyCards = 'lucky-cards',
  Roulette = 'roulette',
}

export interface IContent {
  brandBookFirstColorsSelected: IColor;
  brandBookSecondColorsSelected: IColor;
  showNumbers: boolean;
  showItems: boolean;
  showDescription: boolean;
  items: IItem[];
}

export interface IColor {
  r: number;
  g: number;
  b: number;
}

export interface IItem {
  item: string;
  description: string;
}
