<script lang="ts">
  import type { IExperience } from '../../interfaces/IExperience';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import Paginator from '../../lib/components/paginator/Paginator.svelte';
  import {
    type IExperienceQuery,
    getExperiences,
  } from '../../lib/services/together/together.api';
  import SelectTable from './SelectTable.svelte';
  import FeedbackCardsSection from './feedback-section/FeedbackCardsSection.svelte';

  let selectedExperience: ReturnType<typeof parseDataToTable> | null = null;
  let query: IExperienceQuery = {
    limit: 10,
    offset: 0,
    categoryId: undefined,
    language: undefined,
    onlyVisible: false,
  };

  let experiencesPromise: Promise<{
    experiences: IExperience[];
    count: number;
  }>;
  const fetchExperiences = async () => {
    experiencesPromise = getExperiences(query);
  };
  $: query, fetchExperiences();

  const parseDataToTable = (experience: IExperience) => {
    return {
      ...experience,
      category: experience.category.englishName,
    };
  };
</script>

<div class="flex flex-col gap-4 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-3xl p-2 font-bold">Feedback</h3>
  </div>
  <div class="w-full flex flex-row gap-4">
    {#await experiencesPromise}
      <Loading loadingSize="h-20 w-20" />
    {:then result}
      <div class="min-w-[27rem] flex flex-col gap-2">
        <h2 class="text-2xl font-bold">Experiences</h2>
        <SelectTable
          selectedRow={selectedExperience}
          tableData={result.experiences.map(parseDataToTable)}
          columns={['name', 'language', 'rating']}
          onSelect={(experience) => (selectedExperience = experience)}
        />
        <Paginator
          total={result.count}
          query={query}
          onChange={(newQuery) => {
            query = newQuery;
          }}
        />
      </div>
    {:catch error}
      <p style="color: red">{error.message}</p>
    {/await}
    <FeedbackCardsSection selectedExperience={selectedExperience} />
  </div>
</div>
