import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
  type ICognitoUserSessionData,
} from 'amazon-cognito-identity-js';
import type { ISignupPayload } from 'src/interfaces/ISignupPayload';
import type { IUser } from 'src/interfaces/IUser';
import { get } from 'svelte/store';

import { userPoolStore, userSessionStore } from '../../../store/cognitoStore';
import { userStore } from '../../../store/userStore';
import { Api, get as getApi, post } from '../api-axios';
import { refreshUserToken } from '../cognito/cognito';

export const signUp = async (signUpPayload: ISignupPayload) => {
  await post('/auth/signup', signUpPayload);
};
export const login = async (
  email: string,
  password: string,
  webType: string,
) => {
  const result: any = await post('/auth/login', {
    email,
    password,
    webType,
  });
  if ((window as any).Cypress) {
    await getProfile();
    return;
  }
  const idToken = new CognitoIdToken({ IdToken: result.idToken.jwtToken });
  const accessToken = new CognitoAccessToken({
    AccessToken: result.accessToken.jwtToken,
  });
  Api.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${result.accessToken.jwtToken}`;
  const refreshToken = new CognitoRefreshToken({
    RefreshToken: result.refreshToken.jwtToken,
  });
  const session = new CognitoUserSession({
    AccessToken: accessToken,
    IdToken: idToken,
    RefreshToken: refreshToken,
  } as ICognitoUserSessionData);
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: get(userPoolStore),
  });
  await getProfile();
  cognitoUser.setSignInUserSession(session);
  userSessionStore.set(session);
};

export const getProfile = async () => {
  const user = await getApi<IUser>('/auth/profile');
  userStore.set(user);
};

export const logout = async () => {
  Api.defaults.headers.common['Authorization'] = '';
  const currentUser = get(userPoolStore).getCurrentUser();
  currentUser?.signOut();
  userStore.set(null);
  userSessionStore.set(null);
};

export const refreshSession = async () => {
  try {
    if ((window as any).Cypress) {
      await getProfile();
      return;
    }
    const cognitoUserSession = await refreshUserToken(get(userPoolStore));
    const accessToken = cognitoUserSession.getAccessToken().getJwtToken();
    Api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    await getProfile();
  } catch (error) {
    console.error(error);
  }
};
