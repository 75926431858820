<script lang="ts">
  export let alertMessage: string;
  export let alertDescription = '';
  export let linkMessage = '';
  export let linkDirection = '';
</script>

<div
  class="text-center p-4 mb-4 text-lg text-red-800 rounded-lg bg-red-50 shadow-lg"
  role="alert"
>
  <p>
    <span class="font-medium">{alertMessage}</span>
    {alertDescription}
  </p>
  {#if linkMessage}
    <p class="text-md text-center">
      Go to <a
        class="underline font-semibold hover:text-blue-500"
        href={linkDirection}
      >
        {linkMessage}
      </a>
    </p>
  {/if}
</div>
