<script lang="ts">
  type T = $$Generic;
  export let tableData: T[];

  export let columns: (keyof T)[];

  export let selectedRow: T | null = null;

  export let onSelect: (data: T) => void;
</script>

<table class="w-full text-sm text-left text-gray-500 rounded-md shadow-sm">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    {#each columns as column}
      <th scope="col" class="px-6 py-3">{column}</th>
    {/each}
  </thead>

  <tbody class="bg-white">
    {#each tableData as dataRow, i}
      <tr
        data-cy="feedback-table-row-{i}"
        class={`${
          selectedRow === dataRow ? 'bg-gray-200' : 'bg-white'
        }  border-b hover:bg-gray-200 cursor-pointer`}
        on:click={() => onSelect(dataRow)}
      >
        {#each columns as column}
          <td class="px-6 py-4">{dataRow[column] ? dataRow[column] : '-'}</td>
        {/each}
      </tr>
    {/each}
  </tbody>
</table>
