import Joi from 'joi';

export const boxSchema = Joi.object({
  title: Joi.string().min(2).max(20).required().messages({
    'string.empty': 'Title is required',
    'string.min': 'Title should have more than 2 characters',
    'string.max': 'Title should have less than 25 characters',
    'any.required': 'Title is required',
  }),

  description: Joi.string().min(2).max(100).required().messages({
    'string.empty': 'Description is required',
    'string.min': 'Description should have more than 2 characters',
    'string.max': 'Description should have less than 100 characters',
    'any.required': 'Description is required',
  }),

  maxPlayers: Joi.number().min(2).max(10).required().messages({
    'number.base': 'Max players must be a number',
    'number.min': 'Max players should be at least 2',
    'number.max': 'Max players should be at most 10',
    'any.required': 'Max players is required',
  }),

  webType: Joi.string().valid('b2b', 'couples').required().messages({
    'string.empty': 'Type is required',
    'any.only': 'Invalid type selected',
    'any.required': 'Type is required',
  }),
});
