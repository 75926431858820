<script lang="ts">
  import togetherLogo from '../../assets/Together-01.svg';
  import LoginForm from '../../lib/components/login-form/LoginForm.svelte';
</script>

<div class="flex flex-col min-h-screen justify-center bg-gray-200">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full sm:w-auto px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 p-4 shadow-lg rounded-lg bg-white border-0"
      >
        <div class="flex flex-col gap-4">
          <img
            class="mx-auto h-44 p-6"
            src={togetherLogo}
            alt="Together logo"
          />

          <h2 class="text-center text-2xl font-bold text-gray-900">Log in</h2>
        </div>
        <div class="flex justify-center my-2">
          <LoginForm />
        </div>
      </div>
    </div>
  </div>
</div>
