<script lang="ts">
  import { onMount } from 'svelte';

  import { ExperienceLanguages } from '../../interfaces/IExperience';
  import { getExperiences } from '../../lib/services/together/together.api';
  import MonthlySessionTable from './MonthlySessionTable.svelte';
  import SpecialEventsTable from './SpecialEventsTable.svelte';

  let englishExperiencesOptions: { text: string; value: string }[] = [];
  let spanishExperiencesOptions: { text: string; value: string }[] = [];

  const fetchExperiences = async () => {
    const englishResult = await getExperiences({
      language: ExperienceLanguages.english,
    });
    const spanishResult = await getExperiences({
      language: ExperienceLanguages.spanish,
    });
    englishExperiencesOptions = [
      { text: 'Select', value: '' },
      ...englishResult.experiences.map((exp) => ({
        text: exp.name,
        value: String(exp.id),
      })),
    ];
    spanishExperiencesOptions = [
      { text: 'Select', value: '' },
      ...spanishResult.experiences.map((exp) => ({
        text: exp.name,
        value: String(exp.id),
      })),
    ];
  };
  onMount(fetchExperiences);
</script>

<div class="flex flex-col gap-4 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-3xl p-2 font-bold">Monthly Sessions</h3>
  </div>
  <MonthlySessionTable
    englishExperienceOptions={englishExperiencesOptions}
    spanishExperienceOptions={spanishExperiencesOptions}
  />
  <SpecialEventsTable
    englishExperienceOptions={englishExperiencesOptions}
    spanishExperienceOptions={spanishExperiencesOptions}
  />
</div>
