<script lang="ts">
  import type { IExperience } from '../../../interfaces/IExperience';
  import type { ISessionUser } from '../../../interfaces/ISessionUser';
  import Loading from '../../../lib/components/loading/Loading.svelte';
  import Paginator from '../../../lib/components/paginator/Paginator.svelte';
  import {
    type IExperienceFeedbackQuery,
    getExperienceFeedback,
  } from '../../../lib/services/together/together.api';
  import FeedbackCard from './FeedbackCard.svelte';
  import FeedbackFilters from './FeedbackFilters.svelte';

  export let selectedExperience: Pick<IExperience, 'id' | 'name'> | null = null;

  let feedbackQuery: IExperienceFeedbackQuery = {
    limit: 6,
    offset: 0,
    rating: 0,
    from: new Date().toISOString().split('T')[0],
    to: undefined,
  };

  let isLoading = false;
  let feedbackResult: { count: number; sessionUsers: ISessionUser[] } | null =
    null;
  let errorMessage: string | null = null;

  const fetchFeedback = async () => {
    if (!selectedExperience) return;

    isLoading = true;
    errorMessage = null;

    try {
      feedbackResult = await getExperienceFeedback(
        selectedExperience.id,
        feedbackQuery,
      );
    } catch (error) {
      errorMessage = (error as Error).message;
      feedbackResult = null;
    } finally {
      isLoading = false;
    }
  };

  $: selectedExperience, feedbackQuery, fetchFeedback();
</script>

<div class="min-w-[37rem] grow flex flex-col gap-2">
  <div class="flex flex-row items-start justify-between">
    <h2 class="text-2xl font-bold">
      {selectedExperience?.name
        ? `${selectedExperience.name} feedback`
        : 'Select an experience'}
    </h2>

    <FeedbackFilters
      bind:ratingFilter={feedbackQuery.rating}
      bind:fromDate={feedbackQuery.from}
      bind:toDate={feedbackQuery.to}
      bind:cardsPerPage={feedbackQuery.limit}
    />
  </div>

  {#if isLoading}
    <Loading loadingSize="h-20 w-20" />
  {:else if errorMessage}
    <p style="color: red">{errorMessage}</p>
  {:else if feedbackResult}
    <div class="flex flex-wrap flex-row gap-4">
      {#each feedbackResult.sessionUsers as sessionUser}
        <FeedbackCard sessionUser={sessionUser} />
      {/each}
    </div>

    <Paginator
      total={feedbackResult.count}
      query={feedbackQuery}
      onChange={(newQuery) => {
        feedbackQuery = newQuery;
      }}
    />
  {:else}
    <p>No feedback available.</p>
  {/if}
</div>
