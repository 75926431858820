<script lang="ts">
  import { slide } from 'svelte/transition';

  import { change } from '../../lib/components/helpers/changeDate';
  import type { IGameConfig } from '../games/IGamesResponse';

  export let gameConfig: IGameConfig;
  export let gameName: string;
  export let gameDetailStyles = 'max-w-md';
  export let backgroundColor = 'bg-slate-50';
  export let borderColor = 'border-gray-100';

  let gameElementList: { text: string }[] = [];

  let gameOptions: Record<string, any>;

  const handleCurrentGameConfig = (gameName: string) => {
    const selectedOption = gameOptions[gameName];
    gameElementList = selectedOption.gameElementList;
  };

  $: {
    gameOptions = {
      'Who is': {
        gameElementList:
          'whoIsCards' in gameConfig ? gameConfig.whoIsCards?.slice(1) : [],
      },
      'True or false': {
        gameElementList:
          'trueOrFalseTiles' in gameConfig ? gameConfig.trueOrFalseTiles : [],
      },
      'Double dice board': {
        gameElementList:
          'doubleDiceBoardTiles' in gameConfig
            ? gameConfig.doubleDiceBoardTiles
            : [],
      },
      'Written wishes': {
        gameElementList: null,
      },
    };
    handleCurrentGameConfig(gameName);
  }
</script>

<dl
  class={`${gameDetailStyles} w-full border-2 mt-4 ${backgroundColor} ${borderColor} p-2 text-gray-900 divide-y divide-gray-200"`}
  transition:slide|local
>
  <div class="flex flex-col pb-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Title:</dt>
    <dd class="text-lg font-semibold">{gameConfig.title}</dd>
  </div>
  <div class="flex flex-col pb-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Game name:</dt>
    <dd class="text-lg font-semibold">{gameConfig.gameName}</dd>
  </div>
  <div class="flex flex-col pt-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Create date:</dt>
    <dd class="text-lg font-semibold">
      {change(gameConfig.createdAt)}
    </dd>
  </div>
  <div class="flex flex-col pt-3">
    <dt class="mb-1 text-gray-500 md:text-lg">Update date:</dt>
    <dd class="text-lg font-semibold">
      {change(gameConfig.updatedAt)}
    </dd>
  </div>

  {#if 'whoIsCards' in gameConfig}
    <div class="flex flex-col pb-3">
      <dt class="mb-1 text-gray-500 md:text-lg">Initial Prompt</dt>
      <dd class="text-lg font-semibold">
        {gameConfig.whoIsCards[0].text}
      </dd>
    </div>
  {:else if 'doubleDiceBoardMinRounds' in gameConfig}
    <div class="flex flex-col pt-3">
      <dt class="mb-1 text-gray-500 md:text-lg">Min rounds</dt>
      <dd class="text-lg font-semibold">
        {gameConfig.doubleDiceBoardMinRounds}
      </dd>
    </div>
  {:else if 'wishesAmount' in gameConfig}
    <div class="flex flex-col pt-3">
      <dt class="mb-1 text-gray-500 md:text-lg">Wishes per player</dt>
      <dd class="text-lg font-semibold">
        {gameConfig.wishesAmount}
      </dd>
    </div>
  {/if}
  {#if gameElementList}
    <div class="flex flex-col py-3">
      <dt class="mb-1 text-gray-500 md:text-lg">
        {gameName === 'Who is' ? 'Cards list' : 'Tiles'}
      </dt>
      <dd class="text-lg font-semibold">
        <ul class="list-decimal">
          {#each gameElementList as element}
            <li class="mx-4 ml-8">{element.text}</li>
          {/each}
        </ul>
      </dd>
    </div>
  {/if}
</dl>
