<script lang="ts">
  import type { IPaginatedQuery } from '../../../interfaces/IPaginatedQuery';
  import ButtonUI from './ButtonUI.svelte';

  export let total: number;
  export let query: IPaginatedQuery;
  export let onChange: (newQuery: IPaginatedQuery) => void;

  let pages: number[] = [];

  const calculatePages = () => {
    const totalPages = Math.ceil(total / query.limit);
    return Array.from({ length: totalPages }, (_, index) => index + 1);
  };

  $: query, total, (pages = calculatePages());
  $: currentPage = Math.floor(query.offset / query.limit) + 1;

  const handlePageChange = (newOffset: number) => {
    onChange({ offset: newOffset, limit: query.limit });
  };
</script>

<div class="flex flex-row items-center gap-2">
  <ButtonUI
    on:click={() => handlePageChange(query.offset - query.limit)}
    disabled={query.offset === 0}
  >
    <span>Prev</span>
  </ButtonUI>

  {#each pages as page}
    <ButtonUI
      style={currentPage === page ? 'fill' : 'outline'}
      on:click={() =>
        currentPage !== page && handlePageChange((page - 1) * query.limit)}
    >
      <span>{page}</span>
    </ButtonUI>
  {/each}

  <ButtonUI
    on:click={() => handlePageChange(query.offset + query.limit)}
    disabled={query.offset + query.limit >= total}
  >
    <span>Next</span>
  </ButtonUI>
</div>
