<script lang="ts">
  import { fade } from 'svelte/transition';

  import DangerAlert from '../../lib/components/alerts/DangerAlert.svelte';
  import { change } from '../../lib/components/helpers/changeDate';
  import ViewDetails from '../../lib/components/view-details/ViewDetails.svelte';
  import { get } from '../../lib/services/api-axios';
  import type { IGameConfig } from '../games/IGamesResponse';

  export let id: string;

  let gameName: string;

  const getGameById = async () => {
    const gameById = await get<IGameConfig>(`/game-config/${id}`);
    gameName = gameById.gameName;
    return gameById;
  };
</script>

<div
  class="flex flex-col items-center gap-2 px-4 md:px-10 mx-auto w-full min-h-screen p-4"
  in:fade={{ duration: 250 }}
>
  <div
    class="flex items-center max-w-lg justify-center w-full bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-2xl p-2 text-black font-bold">Game detail</h3>
  </div>

  {#await getGameById() then response}
    <div
      class="flex flex-col max-w-lg w-full gap-4 items-center justify-center"
    >
      <div
        class="flex flex-col w-full bg-white border border-gray-200 rounded-lg shadow-sm p-2 gap-2"
      >
        <div class="text-center">
          <h3 class="text-2xl p-2 text-black/70 font-semibold">
            Title: <strong class="text-black">{response.title}</strong>
          </h3>
        </div>
        <dl
          class="max-w-lg h-full my-auto w-full border-gray-100 p-2 text-gray-900 divide-y divide-gray-200"
        >
          <div class="flex flex-col pb-3">
            <dt class="mb-1 text-gray-500 md:text-lg">Game name</dt>
            <dd class="text-lg font-semibold">{response.gameName}</dd>
          </div>
          <div class="flex flex-col pt-3">
            <dt class="mb-1 text-gray-500 md:text-lg">Create date</dt>
            <dd class="text-lg font-semibold">
              {change(response.createdAt)}
            </dd>
          </div>
          <div class="flex flex-col pt-3">
            <dt class="mb-1 text-gray-500 md:text-lg">Update date</dt>
            <dd class="text-lg font-semibold">
              {change(response.updatedAt)}
            </dd>
          </div>

          <ViewDetails gameConfig={response} gameName={gameName} />
        </dl>
      </div>
    </div>
  {:catch error}
    <DangerAlert alertMessage={`${error.message}`} />
  {/await}
</div>
