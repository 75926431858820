<script lang="ts">
  import type { IBox } from 'src/routes/boxes/IBoxesResponse';
  import { createEventDispatcher } from 'svelte';
  import { link } from 'svelte-navigator';

  export let boxes: IBox[];

  const dispatch = createEventDispatcher();
</script>

<table class="w-full text-sm text-left text-gray-500">
  <thead class="text-xs text-gray-700 uppercase bg-gray-50">
    <tr>
      <th scope="col" class="px-6 py-3">Id</th>
      <th scope="col" class="px-6 py-3">Title</th>
      <th scope="col" class="px-6 py-3">Description</th>
      <th scope="col" class="px-6 py-3">Max Players</th>
      <th scope="col" class="px-6 py-3">Type</th>
      <th scope="col" class="px-6 py-3">Action</th>
    </tr>
  </thead>
  <tbody>
    {#each boxes as box}
      <tr class="bg-white border-b">
        <th scope="row" class="px-6 py-4 font-medium text-gray-900">{box.id}</th
        >
        <td class="px-6 py-4">{box.title}</td>
        <td class="px-6 py-4">{box.description}</td>
        <td class="px-6 py-4">{box.maxPlayers}</td>
        <td class="px-6 py-4">{box.webType}</td>
        <td class="px-6 py-4">
          <a
            href={`/admin/box/${box.id}`}
            use:link
            class="font-medium text-primary hover:underline">View</a
          >
          <a
            href={`/admin/edit/box/${box.id}`}
            use:link
            class="font-medium text-primary hover:underline">Edit</a
          >
          <button
            class="font-medium text-primary hover:underline"
            on:click={() =>
              dispatch('deleteBox', { boxId: box.id, boxTitle: box.title })}
            >Delete</button
          >
        </td>
      </tr>
    {/each}
  </tbody>
</table>
