import Joi from 'joi';

export const gameBoxSchema = Joi.object({
  box: Joi.number().integer().required().messages({
    'number.base': 'You must select a correct box.',
    'any.required': 'A box is required',
  }),

  warmUp: Joi.number().integer().required().messages({
    'number.base': 'You must select a correct game for this phase.',
    'any.required': 'A game is required',
  }),

  play: Joi.number().integer().required().messages({
    'number.base': 'You must select a correct game for this phase.',
    'any.required': 'A game is required',
  }),

  connect: Joi.number().integer().required().messages({
    'number.base': 'You must select a correct game for this phase.',
    'any.required': 'A game is required',
  }),

  memory: Joi.number().integer().required().messages({
    'number.base': 'You must select a correct game for this phase.',
    'any.required': 'A game is required',
  }),
});
