<script lang="ts">
  import { useNavigate } from 'svelte-navigator';

  import { UserRole } from '../../../interfaces/IUser';
  import { ToastNotification } from '../../../lib/services/toast';
  import { userStore } from '../../../store/userStore';
  import { login, logout } from '../../services/together/user.api';
  import type { ILoginCognitoError } from './ILoginCognitoError';
  import type { ErrorField, LoginErrors } from './ILoginErrors';

  const navigate = useNavigate();

  let email: string;
  let password: string;
  let isLoading: boolean;

  let loginErrors: LoginErrors = {
    password: { isError: false, message: '' },
    email: { isError: false, message: '' },
    error: { isError: false, message: '' },
  };

  const handleLoginForm = async (): Promise<void> => {
    isLoading = true;
    resetErrors();
    try {
      await login(email, password, 'couples');
      if ($userStore.role !== UserRole.SUPER_ADMIN) {
        await logout();
        ToastNotification.error('User not authorized');
        navigate('/');
        return;
      }
      navigate('/admin/boxes');
    } catch (error) {
      handleLoginError(error as ILoginCognitoError);
    }
    isLoading = false;
  };

  const handleLoginError = (error: ILoginCognitoError): void => {
    const errorHandlers: Record<string, ErrorField> = {
      NotAuthorizedException: 'email',
      InvalidPasswordException: 'password',
    };

    const errorCode = error.code;
    const errorField = errorHandlers[errorCode] || 'error';

    handleFieldError(errorField, error.message, true);
  };

  const handleFieldError = (
    fieldName: string,
    message: string,
    isError: boolean,
  ): void => {
    loginErrors = {
      ...loginErrors,
      [fieldName]: { isError, message },
    };
  };

  const resetErrors = (): void => {
    const resetFieldErrors = {
      password: { isError: false, message: '' },
      email: { isError: false, message: '' },
      error: { isError: false, message: '' },
    };
    loginErrors = resetFieldErrors;
  };
</script>

<form class="w-96 p-4" on:submit|preventDefault={handleLoginForm}>
  <div>
    <label for="email" class="text-sm font-medium text-gray-900">
      Email address
    </label>
    <div class="mt-2">
      <input
        id="email"
        name="email"
        type="email"
        placeholder="email@gmail.com"
        bind:value={email}
        required
        class={`
          block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1
          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
          focus:ring-inset focus:ring-blue-600
          ${loginErrors.email.isError ? 'ring-2 ring-red-500' : ''} `}
      />
      {#if loginErrors.email.isError}
        <span class="text-sm text-red-500 font-medium"
          >{loginErrors.email.message}</span
        >
      {/if}
    </div>
  </div>
  <div class="mt-3">
    <div class="flex items-center justify-between">
      <label for="password" class="text-sm font-medium text-gray-900">
        Password
      </label>
    </div>
    <div class="mt-2">
      <input
        id="password"
        name="password"
        type="password"
        placeholder="Password"
        bind:value={password}
        required
        class={`
          block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1
          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2
          focus:ring-inset focus:ring-blue-600
          ${loginErrors.password.isError ? 'ring-2 ring-red-500' : ''}`}
      />
      {#if loginErrors.password.isError}
        <span class="text-sm text-red-500 font-medium"
          >{loginErrors.password.message}</span
        >
      {/if}
    </div>
  </div>
  <div class="py-4">
    <button
      type="submit"
      class="bg-primary/80 text-white font-bold w-full h-10 rounded-md transition ease-in duration-200 hover:bg-primary"
      disabled={isLoading}
    >
      {#if isLoading}
        <span class="flex items-center justify-center">
          <svg
            class="animate-spin mr-2 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            />
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </span>
      {:else}
        Log in
      {/if}
    </button>
    {#if loginErrors.error.isError}
      <span class="text-base text-red-500 font-medium flex justify-center"
        >{loginErrors.error.message}</span
      >
    {/if}
  </div>
</form>
