<script lang="ts">
  import { toast } from '@zerodevx/svelte-toast';
  import { link } from 'svelte-navigator';

  import StackedBoxes from '../../assets/svg/StackedBoxes.svelte';
  import type { ICategory } from '../../interfaces/IExperience';
  import DangerAlert from '../../lib/components/alerts/DangerAlert.svelte';
  import DeleteResource from '../../lib/components/delete-resource/DeleteResourceToast.svelte';
  import CategoriesTable from '../../lib/components/tables/CategoriesTable.svelte';
  import { ToastNotification } from '../../lib/services/toast';
  import { getAllCategories } from '../../lib/services/together/together.api';

  const getCategories = async () => {
    return await getAllCategories();
  };

  let categoriesPromise: Promise<{ categories: ICategory[]; count: number }> =
    getCategories();
  let canBeDeleted = true;

  const handleDeleteCategory = async (
    event: CustomEvent<{ categoryName: string; categoryId: number }>,
  ) => {
    if (canBeDeleted) {
      canBeDeleted = false;
      ToastNotification.removeToasts();
      ToastNotification.customizable({
        component: {
          src: DeleteResource,
          props: {
            title: 'Delete a category',
            resourceTitle: `${event.detail.categoryName}`,
            resourceId: `${event.detail.categoryId}`,
            resourceName: 'category',
            resourceUrlPath: 'categories',
          },
          sendIdTo: 'toastId',
        },
        target: 'new-container',
        reversed: true,
        dismissable: false,
        initial: 0,
        theme: {
          '--toastPadding': '0',
          '--toastMsgPadding': '0',
          '--toastBackground': 'transparent',
          '--toastBorderRadius': '1rem',
        },
        intro: { y: -192 },
        onpop: () => {
          const hasBeenDeleted = $toast.some(
            (item) =>
              item.msg === 'The category has been deleted successfully!',
          );

          if (hasBeenDeleted) {
            categoriesPromise = getCategories();
          }

          canBeDeleted = true;
        },
      });
    }
  };
</script>

<div class="flex flex-col gap-4 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm"
  >
    <h3 class="text-3xl p-2 font-bold">List of categories</h3>
  </div>

  {#await categoriesPromise}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full animate-pulse"
    >
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex items-center" />
          <div class="flex flex-col" />
        </div>
      </div>
      <div
        class="h-40 lg:h-24 w-full lg:w-3/6 bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row justify-center items-center p-2" />
      </div>
    </div>
    <div
      class="relative overflow-x-auto h-40 bg-white border border-gray-100 shadow-sm sm:rounded-lg animate-pulse"
    />
  {:then response}
    <div
      class="flex flex-col lg:flex-row gap-4 items-center justify-center w-full"
    >
      <div
        class="h-24 w-full lg:w-3/6 bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div class="flex flex-row p-2 gap-4">
          <div class="flex items-center">
            <div class="bg-gray-200 rounded-full p-3 text-gray-500">
              <StackedBoxes size="h-10 w-10" />
            </div>
          </div>
          <div class="flex flex-col">
            <h3 class="text-lg font-semibold text-gray-500">
              Categories count:
            </h3>
            <span class="text-lg text-center font-bold">{response.count}</span>
          </div>
        </div>
      </div>
      <div
        class="h-40 lg:h-24 w-full lg:w-3/6 bg-white border border-gray-200 rounded-lg shadow-sm p-2"
      >
        <div
          class="flex flex-col lg:flex-row justify-center items-center p-2 gap-1 h-full"
        >
          <a
            class="text-white text-center rounded-md w-full lg:w-auto px-4 py-2 text-sm font-bold bg-primary hover:bg-accent hover:text-black transition ease-in duration-200"
            href="/admin/add/category"
            use:link>Add new category</a
          >
        </div>
      </div>
    </div>
    <div
      class="relative overflow-x-auto bg-white border border-gray-200 shadow-sm sm:rounded-lg"
    >
      <CategoriesTable
        categories={response.categories}
        on:deleteCategory={handleDeleteCategory}
      />
    </div>
  {:catch error}
    <DangerAlert alertMessage={error.message} alertDescription={''} />
  {/await}
</div>
