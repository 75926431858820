<script lang="ts">
  import { validateField } from '../../lib/components/games-forms/validateField';
  import { validateNormalForm } from '../../lib/components/games-forms/validateForm';
  import Input from '../../lib/components/input/Input.svelte';
  import Loading from '../../lib/components/loading/Loading.svelte';
  import { get, post, put } from '../../lib/services/api-axios';
  import { ToastNotification } from '../../lib/services/toast';
  import type { IBoxByIdResponse } from '../view-box/IBoxByIdResponse';
  import type { IBoxForm } from './IBoxForm';
  import type { IBoxValidationInputs } from './IBoxValidationInputs';
  import { boxSchema } from './box.validation.schema';
  import {
    createBoxSuccestfulResponse,
    updateBoxSuccestfulResponse,
  } from './constants';

  export let id: number | undefined = undefined;

  let isEditing = false;
  let isLoading = false;

  let validationInputs: IBoxValidationInputs;

  let boxForm: IBoxForm & { [key: string]: any } = {
    title: { value: '', error: '' },
    description: { value: '', error: '' },
    maxPlayers: { value: 0, error: '' },
    webType: { value: 'couples', error: '' },
  };

  let inputs = [
    {
      name: 'title',
      label: 'Title',
      placeholder: 'Box title',
      type: 'text',
      value: boxForm.title.value,
      error: boxForm.title.error,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'text',
      placeholder: 'This is box description',
      value: boxForm.description.value,
      error: boxForm.description.error,
    },
    {
      name: 'maxPlayers',
      label: 'Max players',
      type: 'number',
      placeholder: '2',
      value: boxForm.maxPlayers.value,
      error: boxForm.maxPlayers.error,
    },
    {
      name: 'webType',
      label: 'Type',
      type: 'select',
      placeholder: 'couples',
      webTypes: [
        { value: 'b2b', text: 'B2B' },
        { value: 'couples', text: 'Couples' },
      ],
      value: boxForm.webType.value,
      error: boxForm.webType.error,
    },
  ];

  const validateFormField = (fieldName: string) => {
    boxForm = validateField(boxForm, fieldName, boxSchema);
  };

  const getBoxById = async () => {
    const box = await get<IBoxByIdResponse>(`/box/${id}`);

    boxForm.title.value = box.title;
    boxForm.description.value = box.description;
    boxForm.maxPlayers.value = box.maxPlayers;
    boxForm.webType.value = box.webType;
    return box;
  };

  const handleSubmit = async () => {
    const validationForm = validateNormalForm<IBoxForm, IBoxValidationInputs>(
      boxForm,
      validationInputs,
      boxSchema,
    );

    boxForm = validationForm;

    for (const key in boxForm) {
      if (boxForm[key as keyof IBoxForm].error) return;
    }

    try {
      isLoading = true;
      const payload = {
        title: boxForm.title.value,
        description: boxForm.description.value,
        maxPlayers: Number(boxForm.maxPlayers.value),
        webType: boxForm.webType.value,
      };

      if (isEditing && id) {
        await put(`/box/${id}`, payload);
        ToastNotification.success(updateBoxSuccestfulResponse);
      } else {
        await post('/box/', payload);
        ToastNotification.success(createBoxSuccestfulResponse);
      }
    } catch (error) {
      ToastNotification.error(error?.response?.data?.message ?? error?.message);
    } finally {
      isLoading = false;
    }
  };

  $: validationInputs = {
    title: boxForm.title.value,
    description: boxForm.description.value,
    maxPlayers: boxForm.maxPlayers.value,
    webType: boxForm.webType.value,
  };

  $: {
    if (id === undefined) {
      isEditing = false;
    } else {
      isEditing = true;
      getBoxById();
    }
  }
</script>

<div class="flex flex-col gap-2 px-4 md:px-10 mx-auto w-full min-h-screen p-4">
  <div
    class="flex items-center justify-center bg-white border border-gray-200 rounded-lg shadow-sm p-2"
  >
    <h3 class="text-2xl font-bold">
      {isEditing ? 'Edit a box' : 'Add new box'}
    </h3>
  </div>

  <div
    class="flex justify-center bg-white border border-gray-200 p-6 rounded-lg"
  >
    <form
      class="flex flex-col items-center gap-3 w-96"
      on:submit|preventDefault={handleSubmit}
    >
      {#each inputs as inputField}
        <Input
          styleClass="w-full"
          on:change={() => validateFormField(inputField.name)}
          bind:value={boxForm[inputField.name].value}
          bind:error={boxForm[inputField.name].error}
          type={inputField.type}
          placeholder={inputField.placeholder}
          label={inputField.label}
          name={inputField.name}
          options={inputField.type === 'select' ? inputField.webTypes : []}
        />
      {/each}
      <button
        class={`bg-primary hover:bg-accent/70 text-white w-full h-full font-bold mt-4 py-3 px-4 rounded hover:text-black transition ease-in duration-150
    ${isLoading ? 'cursor-not-allowed bg-accent/70' : ''}`}
        type="submit"
        disabled={isLoading}
      >
        {#if isLoading}
          <Loading loadingColor="text-white" loadingSize="h-6 w-6" />
        {:else if isEditing}
          Update box
        {:else}
          Create box
        {/if}
      </button>
    </form>
  </div>
</div>
