import { toast } from '@zerodevx/svelte-toast';
import type { SvelteToastOptions } from '@zerodevx/svelte-toast/stores';

export class ToastNotification {
  static success(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
      theme: {
        '--toastColor': 'black',
        '--toastBackground': '#90EE90',
        '--toastBarBackground': '#008000',
      },
      ...options,
    });
  }

  static error(message: string, options?: SvelteToastOptions) {
    toast.push(message, {
      theme: {
        '--toastColor': '#FF0000',
        '--toastBackground': '#FFC0CB',
        '--toastBarBackground': '#FF0000',
      },
      ...options,
    });
  }

  static customizable(options?: SvelteToastOptions) {
    toast.push({
      ...options,
    });
  }
  static removeToasts() {
    toast.pop();
  }
  static removeToastById(id: number) {
    toast.pop(id);
  }
}
