export interface ICategory {
  id: number;
  englishName: string;
  spanishName: string;
  messagesToDeliver: ExperienceMessages[];
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
}

export enum SubscriptionPlan {
  FREE = 0,
  PRO = 1,
}
export const planNames = ['Free', 'Pro'] as const;

export enum ExperienceLanguages {
  english = 'English',
  spanish = 'Spanish',
}

export enum ExperienceMessages {
  WELCOME = 'Welcome',
  REMINDER = 'Reminder',
  GOODBYE = 'Goodbye',
}

export interface IExperience {
  id: number;
  name: string;
  description: string;
  instructions: string;
  templateUrl: string;
  imageUrl: string;
  documentUrl: string;
  allowInPlan: SubscriptionPlan;
  category: ICategory;
  rating: number;
  language: ExperienceLanguages;
  totalRatings: number;
  totalRaters: number;
  durationInMinutes: number;
  customMessage: string;
  minParticipants: number;
  maxParticipants: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}
